/**
 * @author Maxime Louf
 * inspiré de https://www.w3.org/WAI/content-assets/wai-aria-practices/patterns/disclosure/examples/js/disclosure-button.js
 * sous licence W3C pour une partie du code https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 * @version 1.0.0
 *
 * @param buttonNode HTMLElement - L'élément HTML button cible
 * @param mode string - 'default', 'unique'
 * @param delay number - Délai pour l'application du display: none; Utile pour les animations CSS (non compatible avec l'animation 'slide')
 * @param slide boolean - Ajoute une animation 'slide'
 * @param slideDuration number - La vitesse de l'animation, requiert 'slide' à true
 * @export {DisclosureButton, DisclosureButtonClose}
 */

"use strict";

// Facultatif - permet d'animer le disclosure
import {slideUp, slideDown} from './slideToggle';

export class DisclosureButton {
  constructor(buttonNode, delay, slide, slideDuration, hasOutsideClickToCloseComponent = true, displayProperty = 'block') {
    this.buttonNode = buttonNode;
    this.controlledNode = '';
    this.slide = slide ?? this.buttonNode.getAttribute("data-slide") ?? false;
    this.slideDuration = slideDuration ?? this.buttonNode.getAttribute("data-slide-duration") ?? 400;
    this.delay = delay ?? this.buttonNode.getAttribute("data-delay") ?? 0;
    this.id = this.buttonNode.getAttribute("aria-controls");
    this.hasOutsideClickToCloseComponent =
      ((this.buttonNode.getAttribute("data-outside-click") !== null)
        ? (this.buttonNode.getAttribute("data-outside-click") === 'true')
        : hasOutsideClickToCloseComponent);
    this.displayProperty = displayProperty;

    if (this.id) {
      this.controlledNode = document.getElementById(this.id);
    }

    if (!this.buttonNode.hasAttribute('aria-expanded')) {
      this.buttonNode.setAttribute("aria-expanded", "false");
    }

    this.currentState = this.buttonNode.getAttribute('aria-expanded');

    // On vérifie si l'attribut aria-expanded et à true et si l'élément à afficher n'est pas visible
    // Dans ce cas, on affiche l'élément
    if (this.isOpen() && !this.isVisible()) {
      this.showContent()
    }

    // On applique les écouteurs d'événements
    this.buttonNode.addEventListener("click", this.onClick.bind(this));
    this.buttonNode.addEventListener("focus", this.onFocus.bind(this));
    this.buttonNode.addEventListener("blur", this.onBlur.bind(this));
    this.buttonNode.addEventListener("keydown", this.onKeydown.bind(this));
    this.controlledNode.addEventListener("keydown", this.onKeydown.bind(this));
  }

  /**
   * Etat courant du bouton
   */
  isOpen () {
    return (this.currentState === 'true');
  }

  /**
   * Etat courant de l'affichage de l'élément controllé
   */
  isVisible () {
    return (window.getComputedStyle(this.controlledNode).display !== 'none');
  }

  /**
   * Affiche le contenu du nœud controllé
   */
  showContent() {
    const self = this;
    if (self.controlledNode && self.slide) {
      new slideDown(self.controlledNode, self.slideDuration);
    } else if (self.controlledNode) {
      self.controlledNode.style.display = this.displayProperty;
      // On rajoute un timeout insignifiant pour permettre à l'animation CSS d'être visible
      setTimeout(function () {
        self.controlledNode.classList.add('is-visible');
      }, 10);
    }
    this.currentState = true;
    this.buttonNode.setAttribute("aria-expanded", "true");

    // On appelle la fonction outsideClick quand le composant est visible
    if (this.hasOutsideClickToCloseComponent) {
      this.outsideClick(this.buttonNode, this.controlledNode);
    }
  }

  /**
   * Cache le contenu du nœud controllé
   */
  hideContent() {
    const controlledNode = this.controlledNode;
    if (controlledNode && this.slide) {
      new slideUp(controlledNode, this.slideDuration);
    } else if (controlledNode) {
      controlledNode.classList.remove('is-visible');
      setTimeout(function () {
        controlledNode.style.display = "none";
      }, this.delay);
    }
    this.currentState = false;
    this.buttonNode.setAttribute("aria-expanded", "false");
  }

  /**
   * Gestions des événements du clavier
   */
  onKeydown(event) {
    switch(event.key) {
      case 'Esc':
      case 'Escape':
        this.hideContent();
    }
  }

  /**
   * Gestion du clic en dehors du composant
   */
  outsideClick(element, controlledElement) {
    const outsideClickListener = event => {
      if (!element.contains(event.target) && !controlledElement.contains(event.target)) {
        this.hideContent();
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  /**
   * Affiche/Cache le contenu du nœud controllé en fonction de l'état précédent
   */
  toggleExpand() {
    if (this.buttonNode.getAttribute("aria-expanded") === "true") {
      this.hideContent();
    } else {
      this.showContent();
    }
  }

  /* EVENT HANDLERS */

  onClick() {
    this.toggleExpand();
  }

  onFocus() {
    this.buttonNode.classList.add("is-focus");
  }

  onBlur() {
    this.buttonNode.classList.remove("is-focus");
  }

  /** la fonction destroy() remplace le nœud actuel par un clone. Ce qui a pour effet d'enlever les événements attachés au préalable.
   *   Pensez à refaire un document.querySelector pour récupérer le nouveau nœud créé. */
  destroy() {
    const current = this.buttonNode;
    const clone = current.cloneNode(true);
    current.parentNode.replaceChild(clone, current);
  }
}

/*
 *   @constructor CloseDisclosureButton
 */
export class CloseDisclosureButton {
  constructor(buttonNode) {
    this.buttonNode = buttonNode;
    this.id = this.buttonNode.getAttribute("aria-controls");

    this.buttonNode.addEventListener("click", this.onClick.bind(this));
  }

  /* EVENT HANDLERS */

  onClick() {
    document.querySelector(`[aria-expanded="true"][aria-controls="${this.id}"]`)?.click();
  }

  destroy() {
    this.buttonNode.removeEventListener("click", this.onClick.bind(this));
  }
}
