// CSS
require("../../css/GAB_DEFAULT/scss/main.scss");

// JS - composants
require('../../js/components/back-to-top.js');
require('../../js/components/breadcrumb');
require('../../js/components/colonnage');
require('../../js/components/diaporama');
require('../../js/components/flash-info');
require('../../js/components/lightbox');
require('../../js/components/modals');
require('../../js/components/navigation.js');
require('../../js/components/tooltip');
require('../../js/components/video');
require('../../js/components/form/date');
require('../../js/components/form/file');
require('../../js/components/form/tooltip');
require('../../js/components/tabs/index');

//JS - utils
require('../../js/components/aria-toggle.js');
require('../../js/components/setCSSProperties.js');
