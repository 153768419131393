// import your javascript libraries at the top
import { DisclosureButton } from "../utils/disclosure-button";
import {Modal} from "../utils/modals";

// Custom JavaScript
const AutoFlashInfo = () => {
    const triggerButton = document.querySelector(".js-flash-info-btn");
    const closeButton = document.querySelector(".js-flash-info-close");
    if (triggerButton === null) return;
    new DisclosureButton(triggerButton, 0, false, 0, false);

    // Define a function to be called when closeButton is clicked
    function closeButtonClickHandler() {
        // Trigger a click on the triggerButton
        triggerButton.click();
    }

    // Add a click event listener to the closeButton
    closeButton.addEventListener("click", closeButtonClickHandler);
};

const flashInfoModal = () => {
  const flashInfoAlert = document.querySelector('#modal-flash-info');
  Modal(null, flashInfoAlert, true);
}

// Initialize Flash info
window.addEventListener(
    "DOMContentLoaded", flashInfoModal
);

window.addEventListener(
    "DOMContentLoaded",
    AutoFlashInfo,
    false
);
