import {DisclosureButton, CloseDisclosureButton} from "../utils/disclosure-button";

const toggleElements = document.querySelectorAll('.js-aria-toggle');
const closeElements = document.querySelectorAll('.js-aria-close');

toggleElements.forEach((toggleElement) => {
  // Initialisation du disclosure
  const action = new DisclosureButton(
    toggleElement,
    0,
    true,
    300
  );

  toggleElement.addEventListener('click', (event) => {
    /*
     * TODO : checker si ça fonctionne
     * TODO : il faut plusieurs js-aria-toggle dans la page pour tester s'ils se ferment bien quand l'autre s'ouvre
     */
    if (event.currentTarget !== action.buttonNode) {
      if (action.isOpen()) {
        action.hideContent();
      }
    }
  });
});

closeElements.forEach((closeElement) => {
  // Initialisation du disclosure
  new CloseDisclosureButton(closeElement);
});
