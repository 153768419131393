/**
 * @author Maxime Louf
 * Permet d'englober dans une div parente des éléments ayant successivement le même sélecteur.
 * @version 1.0.0
 *
 * @param target string - Un sélecteur CSS
 * @param parentClass string (optionnel) - Le nom de la classe englobante
 * @export {wrapAllInParentDiv}
 */

import {wrapAll} from "./wrapAll";
import {nextUntil} from "./nextUntil";

export const wrapAllInParentDiv = (cssTarget, parentClass = []) => {
  let newDiv;
  const Colonization = document.querySelectorAll(cssTarget);

  for (let i = 0; i < Colonization.length;) {
    // Préparation des tableaux de données qui sont vidé à chaque itération
    let nodes = [];
    let nextUntilResult = [];

    // On insère le premier nœud de la sélection actuelle dans la variable nodes
    nodes.push(Colonization[i]);
    // On insère les nœuds suivants ayant la même classe dans un autre tableau de données
    nextUntilResult = nextUntil(Colonization[i], `:not(${cssTarget})`);
    // On combine les 2 tableaux
    nodes.push(...nextUntilResult);

    // On prépare la prochaine itération en fonction du nombre de nœuds déja insérés
    // Par exemple, si la 1ère itération englobe 3 <div>, la prochaine itération commencera à i = 3
    i += nodes.length;

    // On crée la div englobante et on lui rajoute une classe
    newDiv = document.createElement('div');
    newDiv.classList.add(...parentClass);

    // On utilise la fonction wrapAll pour envelopper la sélection actuelle
    wrapAll(nodes, newDiv);
  }
}
