export const Modal = (trigger, target, openOnLoad = false) => {
  const modal = target;
  if (modal === null) return;
  const openDialogBtn = (!trigger === null) ? null : trigger;
  const closeDialogBtn = target.querySelector(".js-close-dialog-btn");
  const confirmDialogBtn = target.querySelector(".js-confirm-dialog-btn");
  const confirmationMsg = target.querySelector("#confirmation-message");
  const confirmationMsgText = target.querySelector(
    "#confirmation-message-text"
  );
  const successList = ["c-alert--success", "js-visible"];
  const warningList = ["c-alert--warning", "js-visible"];

  const focusableElements =
    'button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), a[href]:not([disabled]), [tabindex]:not([tabindex="-1"])';
  const focusableContent = modal.querySelectorAll(focusableElements);
  const firstFocusable = focusableContent[0];
  const lastFocusable = focusableContent[focusableContent.length - 1];

  /* ------------------ Keydown Events ------------------ */
  const onKeydown = (event) => {
    if (event.key === "Tab" || event.keyCode === 9) {
      if (event.shiftKey) {
        if (document.activeElement === firstFocusable) {
          event.preventDefault();
          lastFocusable.focus();
        }
      } else {
        if (document.activeElement === lastFocusable) {
          event.preventDefault();
          firstFocusable.focus();
        }
      }
    } else if (event.key === "Esc" || event.key === "Escape") {
      modal.close();
    }
  };

  /* ------------------ Open modal ------------------ */
  if (openOnLoad) {
    modal.showModal();
    firstFocusable.focus();
  }

  if (openDialogBtn !== null) {
    openDialogBtn.addEventListener("click", () => {
      modal.showModal();
      firstFocusable.focus();
    });
  }

  /* ------------------ Close modal ------------------ */
  closeDialogBtn.addEventListener("click", () => {
    modal.close();
  });

  /* ------------------ Confirmed mode true/false ------------------ */

  if (confirmDialogBtn !== null) {
    confirmDialogBtn.addEventListener("click", () => {
      // Check the value of data-confirmed attribute
      const dataConfirmed = confirmDialogBtn.getAttribute("data-confirmed");

      if (dataConfirmed === "true") {
        // Data attribute is true, execute the function
        const confirmed = confirm("Are you sure?");
        if (confirmed) {
          confirmationMsgText.textContent = "Action confirmed.";
          confirmationMsg.classList.remove("c-alert--warning");
          confirmationMsg.classList.add(...successList);
          modal.close();
        } else {
          confirmationMsgText.textContent = "Action cancelled.";
          confirmationMsg.classList.remove("c-alert--success");
          confirmationMsg.classList.add(...warningList);
          modal.close();
        }
      } else {
        modal.close();
      }

      return false;
    });
  }

  /* ------------------ Close Modal by clicking outside ------------------ */
  modal.addEventListener("click", function (e) {
    if (e.target.tagName === "DIALOG") modal.close();
  });

  /* ------------------ Close Confirmation Message ------------------ */
  document.addEventListener("click", (event) => {
    if (
      !confirmationMsg.classList.contains("js-visible") ||
      !confirmationMsg.contains(event.target)
    ) {
      return;
    }
    confirmationMsg.classList.remove("js-visible");
  });

  modal.addEventListener("keydown", onKeydown);
};
