// import your javascript libraries at the top
import { TabsAutomatic } from "./lib/autotabs";

// Custom JavaScript
const AutoTabs = () => {
  var tablists = document.querySelectorAll(".js-tab-list");
  for (var i = 0; i < tablists.length; i++) {
    new TabsAutomatic(tablists[i]);
    //class --is-hidden used in this function
  }
};

const load_AutoTabs = window.addEventListener("DOMContentLoaded", AutoTabs);

// export your function at the bottom as follows
export { load_AutoTabs };
