const AutoBackToTop = () => {
    const backToTopButton = document.querySelector(".js-back-to-top");
    if (backToTopButton === null) return;
    // Afficher ou masquer le lien en fonction de la position de la page
    function handleScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        if (scrollPosition > 300) {
            backToTopButton.classList.add("is-visible");
        } else {
            backToTopButton.classList.remove("is-visible");
        }
    }

    // Retourner en haut de la page au clic sur le lien
    function handleBackToTop(event) {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        backToTopButton.blur();
    }

    // Ajouter les événements pour le scroll et le clic
    window.addEventListener("scroll", handleScroll);
    backToTopButton.addEventListener("click", handleBackToTop);
};

const load_AutoBackToTop = window.addEventListener(
    "DOMContentLoaded",
    AutoBackToTop
);

// export your function at the bottom as follows
export { load_AutoBackToTop };
