// Libraire GLightbox https://biati-digital.github.io/glightbox/
import '../glightbox/glightbox.min.css';
import GLightbox from '../glightbox/glightbox.min.js';

document.addEventListener("DOMContentLoaded", () => {
    GLightbox({
        selector: '.js-lightbox',
        touchNavigation: true,
        loop: true,
    });
});
