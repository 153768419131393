import {onresizeend} from "../utils/call-function-on-resize-end";

const setCSSProperties = () => {
  const mainHeader = document.querySelector('.c-main-header');
  const rootStyles = document.querySelector(':root').style;

  rootStyles.setProperty('--header-height', mainHeader.offsetHeight + 'px');
}

setCSSProperties();
onresizeend(setCSSProperties);
