// INPUT FIELD DATE
const InputDate = () => {
  const dateButtons = document.querySelectorAll(".js-input-date-button");

  dateButtons.forEach((dateButton) => {
    const dateBrowserInput = dateButton.previousElementSibling;

    if (
      dateBrowserInput &&
      dateBrowserInput.classList.contains("js-input-date")
    ) {
      dateButton.addEventListener("click", () => {
        try {
          dateBrowserInput.showPicker();
        } catch (error) {
          // Fall back to another picker mechanism
        }
      });
    }
  });
};

const load_InputDate = window.addEventListener("DOMContentLoaded", InputDate);
// export your function at the bottom as follows
export { load_InputDate };
