/**
 * @author Maxime Louf
 * @version 1.0.0
 * 
 * Update:
 * - remplacer la fonction par un objet
 * - Objet exporté
 * 
 * @author Muniir Gopaul
 * 
 */

let breakpoint = {
    breakpoint_tiny: 575,
    breakpoint_small: 792,
    breakpoint_medium: 1024,
    breakpoint_large: 1280,
    breakpoint_huge: 1600   
}

export { breakpoint }