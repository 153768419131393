/**
 * @author Maxime Louf
 * @version 1.0.0
 *
 * @param element HTMLElement - La cible HTML
 * @export function getCSSTransitionDuration - Exemple : getCSSTransitionDuration(element)
 * @return la valeur de la propriété CSS transition-duration en millisecondes (ms)
 *
 * Note : La transition doit être < 10 secondes ou > 10 millisecondes (pour avoir un résultat en ms uniquement même si elle a été définie en secondes)
 */

export default function getCSSTransitionDuration (element) {
    const transitionDuration = parseFloat(window.getComputedStyle(element).transitionDuration)
    return (transitionDuration < 10) ? transitionDuration * 1000 : transitionDuration;
}
