import {Tooltip} from "../../utils/tooltip";

// Custom JavaScript
const load_dom_Tooltip = () => {
  Array.from(document.querySelectorAll(".c-tooltip")).forEach((element) => {
    new Tooltip(element);
  });
};

// Initialize tooltip
const load_Tooltip = window.addEventListener(
  "DOMContentLoaded",
  load_dom_Tooltip
);

// export your function at the bottom as follows
export { load_Tooltip };
