// disclosure buttons
import {
  CloseDisclosureButton,
  DisclosureButton,
} from "../utils/disclosure-button";

// transition duration
import getCSSTransitionDuration from "../utils/getCSSTransitionDuration";

// media query breakpoint object
import {breakpoint as MQ} from "../utils/media-queries.obj";

// Préparation de variables permettant de stocker les événements des boutons
// Niveau 1 - la variable est exposée pour pouvoir récupérer les événements ailleurs
export let toggleSubmenuButtonsEvents = [];
// Niveau 2 et suivants
let toggleButtonsEvents = [];

document.addEventListener("DOMContentLoaded", () => {
  // Initialisation des différents boutons disclosure
  const burgerToggleButton = document.querySelector(".js-burger-toggle");
  const closeButtons = document.querySelectorAll(".js-close");
  const backButtonsMobile = document.querySelectorAll(".js-back-mobile");
  const submenu = document.querySelector(".js-submenu");
  const mainNav = document.getElementById("main-navigation");
  // Icone du burger
  const burgerIcon = document.querySelector(".js-burger-icon");

  // Bouton de fermeture des sous-menus
  closeButtons.forEach((closeButton) => {
    new CloseDisclosureButton(closeButton);
  });

  // Boutons de fermeture des sous-menus en mobile
  backButtonsMobile.forEach((backButtonMobile) => {
    new CloseDisclosureButton(backButtonMobile);
  });

  /** Les boutons d'ouverture des sous-menus n'ont pas les mêmes effets entre desktop et mobile.
   *  Ce script va supprimer les événements sur chacun des boutons et appliquer les bons événements en fonction de la taille d'écran
   *  @param mobile boolean - La fonction est-elle appelée dans un contexte mobile ? */
  function initSubmenus(mobile = false) {
    // On vérifie si les événements ont déja été attaché aux boutons
    // Si oui, on enlève ces événements et on remet à 0 les tableaux d'événements
    if (toggleButtonsEvents.length > 0) {
      toggleButtonsEvents.forEach((toggleButton) => {
        toggleButton.destroy();
      });
      toggleButtonsEvents = [];
    }

    if (toggleSubmenuButtonsEvents.length > 0) {
      toggleSubmenuButtonsEvents.forEach((toggleButton) => {
        toggleButton.destroy();
      });
      toggleSubmenuButtonsEvents = [];
    }

    // Après l'utilisation de la fonction destroy, on cherche les éléments
    const toggleSubmenuButtons =
      document.querySelectorAll(".js-toggle-submenu");
    const toggleButtons = document.querySelectorAll(".js-toggle");

    // On prépare une variable qui va stocker l'action
    let toggleSubmenuButtonEvents;
    let toggleButtonEvents;

    // Boutons d'ouverture des sous-menus
    toggleSubmenuButtons.forEach((toggleSubmenuButton) => {
      // Initialisation du disclosure
      if (mobile) {
        // En mobile, les animations sont géré avec CSS
        toggleSubmenuButtonEvents = new DisclosureButton(
          toggleSubmenuButton,
          getCSSTransitionDuration(submenu)
        );
      } else {
        // En desktop, On utilise le slide js par défaut
        toggleSubmenuButtonEvents = new DisclosureButton(
          toggleSubmenuButton,
          0,
          true,
          300
        );
      }
      // Stocké dans un tableau de donnée
      if (toggleSubmenuButtonEvents) {
        toggleSubmenuButtonsEvents = [
          ...toggleSubmenuButtonsEvents,
          toggleSubmenuButtonEvents,
        ];
      }

      // Pour qu'il n'y ai qu'un seul sous-menu visible à la fois en desktop
      // Ferme les sous-menus déja ouvert
      if (!mobile) {
        toggleSubmenuButton.addEventListener("click", (event) => {
          // On boucle sur le tableau qui contient les disclosure
          toggleSubmenuButtonsEvents.forEach(function (toggleEvents) {
            // S'il s'agit de l'élément actuellement cliqué, il n'est pas concerné
            if (event.target === toggleEvents.buttonNode) return;
            // On replie les autres sous-menus
            if (toggleEvents.isOpen()) {
              toggleEvents.hideContent();
            }
          });
        });
      }
    });

    // Boutons d'ouverture des sous-menus de niveau 2 et suivants (pas d'effet en desktop actuellement)
    toggleButtons.forEach((toggleButton) => {
      // Initialisation du disclosure
      if (mobile) {
        // En mobile, les animations sont géré avec CSS
        toggleButtonEvents = new DisclosureButton(
          toggleButton,
          getCSSTransitionDuration(submenu)
        );
      }

      // Stocké dans un tableau de donnée
      if (toggleButtonEvents) {
        toggleButtonsEvents = [...toggleButtonsEvents, toggleButtonEvents];
      }
    });
  }

  function burgerEvent() {
    // Gestion du bouton burger
    burgerToggleButton.addEventListener("click", () => {
      // Au clique du bouton burger, on change son icône
      burgerIconToggle();

      // On ferme les sous-menus par défaut
      backButtonsMobile?.forEach((backButtonMobile) => {
        backButtonMobile.click();
      });
    });
  }

  // Gestion de l'icône du bouton burger
  function burgerIconToggle() {
    if (burgerToggleButton.getAttribute("aria-expanded") === "true") {
      burgerIcon.classList.toggle("o-icon--close");
      burgerIcon.classList.remove("o-icon--burger");
    } else {
      burgerIcon.classList.add("o-icon--burger");
      burgerIcon.classList.remove("o-icon--close");
    }
  }

  let burgerToggleButtonEvent;
  // Gestion du responsive
  const mediaQuery = window.matchMedia(`(min-width: ${MQ.breakpoint_small}px)`);

  function handleTabletChange(e) {
    if (e.matches) {
      if (document.getElementById("main-navigation"))
        // Par défault, la navigation principale est toujours visible en desktop
        document.getElementById("main-navigation").removeAttribute("style");

      // Initialisation des sous-menus desktop
      initSubmenus();

      // Sous-menu de niveau 3
      const submenusLevel3 = document.querySelectorAll(".js-inner-submenu");
      submenusLevel3.forEach((element) => {
        element.style.display = "block";
      });
    } else {
      // Initialisation des sous-menus mobile
      initSubmenus(true);

      // Bouton disclosure du menu burger
      // On prévient les événements du burger de s'activer plusieurs fois
      if (burgerToggleButtonEvent === undefined) {
        burgerToggleButtonEvent = new DisclosureButton(
          burgerToggleButton,
          getCSSTransitionDuration(mainNav)
        );
        burgerEvent();
        burgerIconToggle();
      }
    }
  }

  mediaQuery.addEventListener("change", handleTabletChange);

  handleTabletChange(mediaQuery);
});
