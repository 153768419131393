/**
 * @author Maxime Louf
 * inspiré de https://w3bits.com/javascript-slidetoggle/
 * @version 1.0.0
 *
 * @param element HTMLElement - La cible HTML
 * @param duration number (optionnel) - La vitesse de l'animation. Défaut 500
 * @export {slideUp, slideDown, slideToggle} - Exemple : new slideToggle(element, duration)
 */

export class slideUp {
    constructor(element, duration) {
        this.target = element;
        this.duration = duration ?? 500;

        this.doSlideUp();
    }

    doSlideUp() {
        this.target.style.transitionProperty = 'height, margin, padding';
        this.target.style.transitionDuration = this.duration + 'ms';
        this.target.style.boxSizing = 'border-box';
        this.target.style.height = this.target.offsetHeight + 'px';
        this.target.offsetHeight;
        this.target.style.overflow = 'hidden';
        this.target.style.height = 0;
        this.target.style.paddingTop = 0;
        this.target.style.paddingBottom = 0;
        this.target.style.marginTop = 0;
        this.target.style.marginBottom = 0;
        window.setTimeout( () => {
            this.target.style.display = 'none';
            this.target.style.removeProperty('height');
            this.target.style.removeProperty('padding-top');
            this.target.style.removeProperty('padding-bottom');
            this.target.style.removeProperty('margin-top');
            this.target.style.removeProperty('margin-bottom');
            this.target.style.removeProperty('overflow');
            this.target.style.removeProperty('transition-duration');
            this.target.style.removeProperty('transition-property');
        }, this.duration);
    }
}
export class slideDown {
    constructor(element, duration) {
        this.target = element;
        this.duration = duration ?? 500;

        this.doSlideDown();
    }

    doSlideDown() {
        this.target.style.removeProperty('display');
        let display = window.getComputedStyle(this.target).display;

        if (display === 'none')
            display = 'block';

        this.target.style.display = display;
        let height = this.target.offsetHeight;
        this.target.style.overflow = 'hidden';
        this.target.style.height = 0;
        this.target.style.paddingTop = 0;
        this.target.style.paddingBottom = 0;
        this.target.style.marginTop = 0;
        this.target.style.marginBottom = 0;
        this.target.offsetHeight;
        this.target.style.boxSizing = 'border-box';
        this.target.style.transitionProperty = "height, margin, padding";
        this.target.style.transitionDuration = this.duration + 'ms';
        this.target.style.height = height + 'px';
        this.target.style.removeProperty('padding-top');
        this.target.style.removeProperty('padding-bottom');
        this.target.style.removeProperty('margin-top');
        this.target.style.removeProperty('margin-bottom');
        window.setTimeout( () => {
            this.target.style.removeProperty('height');
            this.target.style.removeProperty('overflow');
            this.target.style.removeProperty('transition-duration');
            this.target.style.removeProperty('transition-property');
        }, this.duration);
    }
}

export class slideToggle {
    constructor(element, duration) {
        this.target = element;
        this.duration = duration ?? 500;

        this.doSlideDownToggle();
    }

    doSlideDownToggle() {
        if (window.getComputedStyle(this.target).display === 'none') {
            new slideDown(this.target, this.duration);
        } else {
            new slideUp(this.target, this.duration);
        }
    }
}
