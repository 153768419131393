import {Modal} from "../utils/modals";

const AutoModal = () => {
  const triggers = document.querySelectorAll('.js-modal');
  triggers.forEach((trigger) => {
    const modalId = trigger.getAttribute('aria-controls');
    const modal = document.querySelector(modalId);
    Modal(trigger, modal);
  });
}

window.addEventListener("DOMContentLoaded", AutoModal, false);
