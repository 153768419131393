// disclosure buttons
import {
  DisclosureButton,
} from "../utils/disclosure-button";
const AutoBreadcrumb = () => {
  // Get the button and the breadcrumb list
  const breadcrumbButton = document.querySelector(".js-breadcrumb-button");

  if (breadcrumbButton) {
    new DisclosureButton(breadcrumbButton, 0, false, 0, false, 'flex');
  }
};

window.addEventListener(
  "DOMContentLoaded",
  AutoBreadcrumb
);
