// INPUT FIELD FILE
const InputFile = () => {
  const fileButtons = document.querySelectorAll(".js-input-file-button");

  fileButtons.forEach((fileButton) => {
    const fileBrowserInput = fileButton.previousElementSibling;
    const fileBrowserName = fileButton.querySelector(".js-input-file-name");

    fileButton.addEventListener("click", () => {
      fileBrowserInput.click();
    });

    fileBrowserInput.addEventListener("change", () => {
      const selectedFileNames = Array.from(fileBrowserInput.files).map(
        (file) => file.name
      );

      if (selectedFileNames.length === 1) {
        fileBrowserName.innerText = selectedFileNames[0];
      } else if (selectedFileNames.length > 1) {
        fileBrowserName.innerText = selectedFileNames.join(", ");
      }
    });
  });
};

const load_InputFile = window.addEventListener("DOMContentLoaded", InputFile);

// export your function at the bottom as follows
export { load_InputFile };
