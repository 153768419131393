/**
 * @author Maxime Louf
 * Permet d'appeler une fonction après un événement 'resize'
 * @version 1.0.0
 *
 * @param func function - La fonction à exécuter
 * @param time integer - Le délai d'exécution après la fin de l'événement resize
 * @export {onresizeend} function
 */

export const onresizeend = (func, time = 100) => {
  // Debounce
  function debounce(func, time){
    let timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
    };
  }

  window.addEventListener("resize", debounce(func, time));
}
