// import your javascript libraries at the top
import { DisclosureButton } from "../utils/disclosure-button";

const VideoTranscript = () => {
  var triggerButtons = document.querySelectorAll(".js-video-transcript-btn");

  triggerButtons.forEach((triggerButton) => {
    new DisclosureButton(triggerButton);
  });
};

const VideoPlayer = () => {
  const videoElements = document.querySelectorAll(".js-video-player");

  if (videoElements) {
    videoElements.forEach((video) => {
      const trigger = video
        .closest(".c-video")
        .querySelector(".js-video-play-btn");
      const target = video;

      trigger.addEventListener("click", function () {
        this.style.display = "none"; // hide button layer
        target.play(); // play video
      });
    });
  }
};

const VideoEmbed = () => {
  const videoElements = document.querySelectorAll(".js-video-embed");

  if (videoElements) {
    videoElements.forEach((video) => {
      const trigger = video
        .closest(".c-video")
        .querySelector(".js-video-play-btn");
      const target = video;

      trigger.addEventListener("click", function () {
        this.style.display = "none"; // hide button layer

        if (target.classList.contains("js-youtube-player")) {
          // Check if "autoplay=0" exists in the src, replace or append accordingly
          target.src = target.src.includes("autoplay=0")
            ? target.src.replace("autoplay=0", "autoplay=1")
            : target.src + "?autoplay=1";
        } else {
          /*
           * TODO : @muniir cater for external js on commit
           * */
          // Assuming the Vimeo Player API was included
          /*var vimeoPlayer = new Vimeo.Player(target);
          vimeoPlayer.play();*/
        }
      });
    });
  }
};

const load_Video = window.addEventListener("DOMContentLoaded", () => {
  VideoTranscript();
  VideoPlayer();
  VideoEmbed();
});

// export your function at the bottom as follows
export { load_Video };
